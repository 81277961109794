<template>
    <div>
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label="训练集名称:">
					<el-input clearable v-model="filters.trainName"></el-input>
				</el-form-item>
                <el-form-item label="训练状态:">
                    <el-select v-model="filters.trainStatus" clearable>
                        <el-option v-for="item in trainStatusList" :key="item.key" :label="item.value"
                            :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否发布:">
                    <el-select v-model="filters.isPublished" clearable>
                        <el-option label="是" value="true"></el-option>
                        <el-option label="否" value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <el-table :data="trainSetList" highlight-current-row @current-change="selectCurrentRow" v-loading="listLoading"
            @selection-change="selsChange" style="width: 100%" border>
            <!-- <el-table-column prop="trainNo" label="训练集编号" width="110" align="center"></el-table-column> -->
            <el-table-column prop="trainName" label="训练集名称" align="center"></el-table-column>
            <el-table-column prop="dataCount" label="训练数量" width="130" align="center"></el-table-column>
            <el-table-column label="训练状态" align="center" width="130">
                <template slot-scope="scope">
                    <el-tag :color="scope.row.trainStatusColor" effect="dark" disable-transitions>{{
            scope.row.trainStatusName }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="槽位准确度" width="150" align="center" show-overflow-tooltip>
                <template slot-scope="scope">{{ (scope.row.slotScore * 100).toFixed(2) }}%</template>
            </el-table-column>
            <el-table-column label="意图准确度" width="200" align="center">
                <template slot-scope="scope">{{ (scope.row.intentScore * 100).toFixed(2) }}%</template>
            </el-table-column>
            <el-table-column label="是否发布" width="100" align="center">
                <template slot-scope="scope">
                    <el-tag effect="dark" :type="scope.row.isPublished ? 'success' : 'info'" disable-transitions>{{
            scope.row.isPublished ? "已发布" : "未发布" }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createUserName" label="创建人" width="100" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="180" align="center"></el-table-column>
            <el-table-column align="center" label="操作" width="100">
                <template slot-scope="scope">
                    <el-button type="text" size="small" v-if="!scope.row.trainStatus"
                        @click.native="handleTrain(scope.row)">训练</el-button>
                    <el-button type="text" size="small" v-if="!scope.row.isPublished && scope.row.trainStatus === 4"
                        @click.native="releaseFunction(scope.row)">发布</el-button>
                    <el-button type="text" size="small" @click.native="deleteFunction(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-col :span="24" class="pageBar" align="center">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <el-dialog title="训练配置" :visible.sync="trainDialogFormVisible" v-model="trainDialogFormVisible"
            :close-on-click-modal="false">
            <el-form :model="startTrainForm" ref="startTrainForm"
                style="margin-left: 20px; padding-bottom: 0px;width:80%" label-width="130px">
                <el-form-item label="验证集比例:" placeholder="请输入验证集比例">
                    <span slot="label">
                        验证集比例:
                        <el-tooltip effect="dark" content="数据中用于验证模型准确性的比例，一般0.1-0.2之间" placement="top">
                            <i style="margin-left: 5px;" class="el-icon-warning"></i>
                        </el-tooltip>
                    </span>
                    <el-input-number v-model="startTrainForm.trainTestRatio" :min="0" :max="1"></el-input-number>
                </el-form-item>
                <el-form-item label="随机数种子:" placeholder="请输入随机数种子">
                    <span slot="label">
                        随机数种子:
                        <el-tooltip effect="dark" content="分配验证集时程序使用的随机数种子，可随意修改，一般为六位数" placement="top">
                            <i style="margin-left: 5px;" class="el-icon-warning"></i>
                        </el-tooltip>
                    </span>
                    <el-input-number v-model="startTrainForm.randomSeed" :precision="0"></el-input-number>
                </el-form-item>
                <el-form-item label="批量大小:" placeholder="请输入批量大小">
                    <span slot="label">批量大小:
                        <el-tooltip effect="dark" content="训练一步加载的数据量，数值越大，占用显存越多；数值越小，则更容易找到更好的训练参数，但是速度会变慢，可适当选小一些"
                            placement="top">
                            <i style="margin-left: 5px;" class="el-icon-warning"></i>
                        </el-tooltip>
                    </span>
                    <el-input-number v-model="startTrainForm.batchSize" :precision="0"></el-input-number>
                </el-form-item>
                <el-form-item label="训练步数:" placeholder="请输入训练步数">
                    <span slot="label">
                        训练步数:
                        <el-tooltip effect="dark"
                            content="数据集训练的轮数，轮数越大，模型和训练结果越拟合，但训练集结果达到100%，继续训练就无效了，因此需要设置一个合理的数值，一般20次左右即可"
                            placement="top">
                            <i style="margin-left: 5px;" class="el-icon-warning"></i>
                        </el-tooltip>
                    </span>
                    <el-input-number v-model="startTrainForm.epoch" :precision="0"></el-input-number>
                </el-form-item>
                <el-form-item label="学习率:" placeholder="请输入学习率">
                    <span slot="label">学习率:
                        <el-tooltip effect="dark" content="模型调整参数时的幅度，数值过大，则训练过程中容易跳过最优参数；数值过小，则调整到最优参数可能越慢，一般为5-6的小数"
                            placement="top">
                            <i style="margin-left: 5px;" class="el-icon-warning"></i>
                        </el-tooltip>
                    </span>
                    <el-input-number v-model="startTrainForm.learningRate" :min="0" :max="1"></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="trainDialogFormVisible = false;">取 消</el-button>
                <el-button type="primary" @click.native="saveStartTrain" :loading="startTrainLoading">训 练</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import {
    getTrainList,
    getByNamespace,
    startTrain,
    publishTrain,
    deleteTrain,
} from "../../api/api";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import dayjs from 'dayjs'
import Qs from "qs";
export default {
    components: { Toolbar },
    data() {
        return {
            trainStatusList: [],
            trainSetList: [],
            listLoading: false,
            currentRow: null,
            //列表选中列
            sels: [],
            //增删改查按钮
            buttonList: [],
            filters: {
                trainName: null,
                trainStatus: null,
                isPublished: null,
            },
            //关于分页的obj
            pages: {
                pageSize: 20, //默认每页条数
                pageArr: [10, 20, 30, 40], //默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            startTrainForm: {
                batchSize: 5,//每次训练数据量
                epoch: 20,//训练轮数
                learningRate: 0.00001,//学习率
                randomSeed: 0,//随机数种子
                trainId: null, //训练id
                trainTestRatio: 0.1,//训练集比例
            },
            trainFormFormRules: {
                batchSizeErrorType: [
                    { required: true, message: '批量大小', trigger: 'blur' }
                ],
                epochErrorType: [
                    { required: true, message: '请输入训练步数', trigger: 'blur' }
                ],
                learningRateErrorType: [
                    { required: true, message: '请输入学习率', trigger: 'blur' }
                ],
                randomSeedErrorType: [
                    { required: true, message: '请输入随机数种子', trigger: 'blur' }
                ],
                trainTestRatioErrorType: [
                    { required: true, message: '请输入训练集比例', trigger: 'blur' }
                ],
            },
            //显示训练层
            trainDialogFormVisible: false,
            startTrainLoading: false,
            deleteTrainLoading: false,
            publishTrainLoading: false
        }
    },
    methods: {
        // 清空筛选框内容，重新获取数据
        clearContent() {
            this.getListData();
        },
        //当前行发生变化时
        selectCurrentRow(val) {
            this.currentRow = val;
        },
        //选项发生变化时
        selsChange: function (sels) {
            this.sels = sels;
        },
        //获取训练状态
        getTrainStatus() {
            var _this = this;
            var params = {
                namespace: 'BizService.OmsAicsService.TrainStatus',
            };
            getByNamespace(params).then((res) => {
                var data = res.data;
                // var resData = [data.response];
                if (data.success) {
                    _this.trainStatusList = data.response;
                }
            });
        },
        getListData() {
            this.listLoading = true;
            var params = {
                isPublished: this.filters.isPublished,
                trainName: this.filters.trainName,
                trainStatus: this.filters.trainStatus,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            };
            getTrainList(params).then((res) => {
                this.listLoading = false;
                // this.trainSetList = res.data.response.data;	

                this.trainSetList = res.data.response.data.map(function (e) {
                    if (e.isPublished) {
                        e.isPublishedC = "rgb(104, 208, 100)";
                    } else {
                        e.isPublishedC = "#909399";
                    }
                    var trainStatusColor = "";
                    var trainStatusName = "";
                    //训练状态：0 未开始；1 训练中；2 训练中断；3 训练暂停；4 训练完成
                    switch (e.trainStatus) {
                        case 0:
                            trainStatusColor = "rgb(230, 230, 230)";
                            trainStatusName = "未开始";
                            break;
                        case 1:
                            trainStatusColor = "rgb(255, 136, 64)";
                            trainStatusName = "训练中";
                            break;
                        case 3:
                            trainStatusColor = "rgb(255, 136, 64)";
                            trainStatusName = "训练暂停";
                            break;
                        case 2:
                            trainStatusColor = "red";
                            trainStatusName = "训练中断";
                            break;
                        case 4:
                            trainStatusColor = "rgb(104, 208, 100)";
                            trainStatusName = "训练完成";
                            break;
                    }
                    e.trainStatusColor = trainStatusColor;
                    e.trainStatusName = trainStatusName;
                    return e;
                });
                this.pages.dataCount = res.data.response.dataCount;
                this.pages.pageIndex = res.data.response.pageIndex;
                if (res.data.response.pageSize > 0) {
                    this.pages.pageSize = res.data.response.pageSize;
                }
            });
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
        callFunction(item) {
            this[item.func].apply(this, item);
        },
        //分页
        handleCurrentChange(val) {
            // if(this.filters.name != '' || this.filters.selectc != false){
            //   this.pages.pageIndex = 1
            // }else{
            this.pages.pageIndex = val;
            // }      
            this.getListData();
        },
        handleSizeChange(val) {
            this.pages.pageSize = val;
            this.getListData();
        },
        // 显示训练层
        handleTrain(val) {
            this.startTrainForm.trainId = val.trainId;//训练id            
            this.startTrainForm.batchSize = 5;//每次训练数据量
            this.startTrainForm.epoch = 20;//训练轮数
            this.startTrainForm.learningRate = 0.00001;//学习率
            this.startTrainForm.randomSeed = dayjs().format("YYMMDD");//随机数种子
            this.startTrainForm.trainTestRatio = 0.1;//训练集比例
            this.trainDialogFormVisible = true;
        },
        //保存询量集
        saveStartTrain(val) {
            // this.$refs.startTrainForm.validate((valid) => {
            // 	if (valid) {
            this.$confirm('确认训练吗？', '提示', {}).then(() => {
                this.startTrainLoading = true;
                let params = Object.assign({}, this.startTrainForm);
                startTrain(params).then((res) => {
                    if (util.isEmt.format(res)) {
                        this.startTrainLoading = false;
                        return;
                    }
                    if (res.data.success) {
                        this.startTrainLoading = false;
                        this.trainDialogFormVisible = false;
                        this.$message({
                            message: res.data.message,
                            type: 'success'
                        });
                        this.getListData();
                    }
                    else {
                        this.startTrainLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'error'
                        });
                    }
                });
            });
            //     }
            // });
        },
        //发布
        releaseFunction(val) {
            this.$confirm('确定发布吗？', '提示', {}).then(() => {
                this.publishTrainLoading = true;
                let params = {
                    trainId: val.trainId
                }
                publishTrain(params).then((res) => {
                    if (util.isEmt.format(res)) {
                        this.publishTrainLoading = false;
                        return;
                    }
                    if (res.data.success) {
                        this.publishTrainLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'success'
                        });
                        this.getListData();
                    }
                    else {
                        this.publishTrainLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'error'
                        });
                    }
                });
            });
        },
        // 删除
        deleteFunction(val) {
            this.$confirm('确定删除吗？', '提示', {}).then(() => {
                this.deleteTrainLoading = true;
                let params = {
                    trainId: val.trainId
                }
                deleteTrain(params).then((res) => {
                    if (util.isEmt.format(res)) {
                        this.deleteTrainLoading = false;
                        return;
                    }
                    if (res.data.success) {
                        this.deleteTrainLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'success'
                        });
                        this.getListData();
                    }
                    else {
                        this.deleteTrainLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'error'
                        });
                    }
                });
            });
        }
    },
    mounted() {
        this.getTrainStatus();
        this.getListData();
        let routers = window.localStorage.router
            ? JSON.parse(window.localStorage.router)
            : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
};
</script>
<style lang="stylus" scoped>
.rolesTop {
	width: 100%;
	display: flex;
}

.rolesInp {
	width: 300px;
	margin-right: 20px;
}

.pageBar {
  	margin-top: 15px;
}

.el-tag--dark{
    border:none;
}
</style>